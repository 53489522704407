import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import mixpanel from 'mixpanel-browser'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY || '')
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
