import { supabase } from '../services/supabaseService'

// Add supabase types
export interface AuthRepositoryType {
    resetPassword: (password: string) => Promise<void>
}

export const authRepository = (): AuthRepositoryType => {
    const resetPassword: AuthRepositoryType['resetPassword'] = async (password): Promise<void> => {
        const user = await supabase.auth.getUser()
        if (user?.data?.user) {
            const { data, error } = await supabase.auth.updateUser({
                password: password,
            })
            if (error) {
                throw new Error('Token not valid, we cannot authenticate the user')
            }

            return
        }

        throw new Error('Token not valid, we cannot authenticate the user')
    }

    return {
        resetPassword,
    }
}
