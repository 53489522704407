import { Subscription } from '../../domain/models/Subscription'

export const subscriptionParser = {
    toDomain: (entity: any): Subscription => {
        return {
            plan: entity.plan,
            usedCredits: entity.used_credits,
            customerId: entity.customer_id,
        }
    },
}
